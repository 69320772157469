<template>
  <div class="box box--lg box--white d-flex-centered">
    <toko-no-data-icon />
    <h2 class="text-uppercase text-bold mt-24 lp-01">{{ title }}</h2>
    <slot />
  </div>
</template>

<script>
const TokoNoDataIcon = () => import('@/ui/toko-icons/TokoNoData')

export default {
  name: 'NoData',

  props: {
    title: {
      type: String,
      required: true
    }
  },

  components: {
    TokoNoDataIcon
  }
}
</script>
